import { Card, Col, Form, Row, Typography } from 'antd';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TenantInfo from '../../../../components/TenantIdInfo';
import {
  chileStateCodeMap,
  countryCodeMap,
  countryStateMap,
  indianStateStateCodeMap,
  stateList
} from '../../../../data';
import { getDayJsObjectWithFormattedString } from '../../../../helpers/date.helpers';
import _ from '../../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../../helpers/toast.helpers';
import PrimaryLayout from '../../../../layouts/primary-layout';
import { useLoader } from '../../../../stores/use-loader';
import CustomerForm from '../../components/CustomerForm';
import { loyaltyService } from '../../services/loyalty.service';
import { ICustomer } from '../../types/customer';
import { useTranslation } from 'react-i18next';

interface IEditCustomerProps {}

const EditCustomer: React.FunctionComponent<IEditCustomerProps> = props => {
  const [editForm] = Form.useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));
  const { id } = useParams();
  const [customerDetails, setCustomerDetails] = React.useState({} as ICustomer);
  const { t } = useTranslation();

  React.useEffect(() => {
    loadInitialCustomeDetails();
  }, []);

  const handleEditCustomer = async () => {
    setLoading(true);
    const {
      customer_id,
      first_name,
      last_name,
      email,
      dial_code,
      phone_number,
      channel_identity_info,
      date_of_birth,
      sign_up_date,
      customer_type,
      refered_by,
      supply_type_code,
      postal_code,
      location,
      state,
      country,
      member_type
    } = editForm.getFieldsValue();

    let ChannelInfo = channel_identity_info?.map((item: any) => {
      let cnName = item?.channel_name?.split('type')[0].trim();
      let obj = {
        ...item,
        channel_name: cnName
      };
      return obj;
    });
    const request = {
      customer_id: customer_id.trim(),
      first_name,
      last_name,
      email,
      dial_code,
      phone_number,
      channel_identity_info: ChannelInfo ? ChannelInfo : [],
      date_of_birth: date_of_birth ? date_of_birth.format('YYYY-MM-DD') : undefined, // YYYY-MM-DD
      sign_up_date: sign_up_date ? sign_up_date.format('YYYY-MM-DD') : undefined, // YYYY-MM-DD
      customer_type,
      refered_by,
      supply_type_code,
      postal_code,
      location,
      state: state.trim(),
      country: country.trim(),
      // state_code: state && country ? countryStateMap[country][state]?.state_code : undefined,
      // country_code: countryCodeMap[country],
      member_type
    };

    const { errors } = await loyaltyService.putCustomer(request);
    if (!_.isEmpty(errors)) displayErrorNotifications(errors);
    else {
      displaySuccessNotification({ message: t('customerUpdatedSuccessfully') });
      handleGoBack();
    }
    setLoading(false);
  };

  const handleGoBack = () => {
    if (searchParams.get('backUrl')) {
      navigate(searchParams.get('backUrl') as string);
    } else {
      navigate('/loyalty/customers');
    }
  };

  const loadInitialCustomeDetails = async () => {
    setLoading(true);
    const { data, errors } = await loyaltyService.getCustomerDetails(id as string);
    if (_.isEmpty(errors)) {
      populateEditFormWithData(data);
      setCustomerDetails(data);
    }
    setLoading(false);
  };

  const populateEditFormWithData = (data: any) => {
    const {
      customer_id,
      first_name,
      last_name,
      email,
      dial_code,
      phone_number,
      channel1,
      channel2,
      channel3,
      alternate_id,
      alternate_id2,
      alternate_id3,
      date_of_birth,
      sign_up_date,
      customer_type,
      refered_by,
      supply_type_code,
      channel,
      site_id,
      postal_code,
      location,
      state,
      country,
      channel_identity_info,
      member_type,
      country_code,
      state_code
    } = data;

    let ChannelInfo = channel_identity_info?.map((item: any) => {
      let cnName = `${item?.channel_name} type - ${item.channel_type}`;
      let obj = {
        ...item,
        channel_name: cnName
      };
      return obj;
    });

    function getStateNameByCode(stateCode: any, stateData: any) {
      for (const state in stateData) {
        if (stateData[state].state_code === stateCode) {
          return stateData[state].state_name;
        }
      }
      return 'State not found';
    }

    const listState = (countryData: any) => {
      if (country_code === 'CL' || country_code === 'CHL') {
        let data = getStateNameByCode(state_code, chileStateCodeMap);
        return data;
      }
      if (country_code === 'IN') {
        let data = getStateNameByCode(state_code, indianStateStateCodeMap);
        return data;
      }
    };

    let countryData = country_code ? (country_code === 'CL' || country_code === 'CHL' ? 'Chile' : 'India') : country;
    let getStateCode = country_code ? listState(countryData) : state;

    const formValues = {
      customer_id, //
      first_name,
      last_name,
      email,
      dial_code,
      phone_number: phone_number ? phone_number : null,
      channel1,
      alternate_id1: alternate_id,
      channel2,
      alternate_id2,
      channel3,
      alternate_id3,
      customer_type,
      refered_by,
      supply_type_code,
      channel,
      site_id,
      postal_code,
      location,
      state: getStateCode,
      country: countryData,
      member_type,
      date_of_birth: date_of_birth ? getDayJsObjectWithFormattedString(date_of_birth, 'YYYY-MM-DD') : null,
      sign_up_date: sign_up_date ? getDayJsObjectWithFormattedString(sign_up_date, 'YYYY-MM-DD') : null,
      channel_identity_info: ChannelInfo ? ChannelInfo : []
    };
    editForm.setFieldsValue(formValues);
  };
  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card>
          <Row justify={'space-between'} className="mb-4">
            <Col>
              <Typography.Title level={3} className="text-[#2e2a5b]">
                {t('customer')} - {`${customerDetails.first_name || ''} ${customerDetails.last_name || ''}`}
              </Typography.Title>
            </Col>
          </Row>
          <TenantInfo />
          <section className="mt-4">
            <CustomerForm
              isUpdate
              formInstance={editForm}
              handleFinish={handleEditCustomer}
              handleGoBack={handleGoBack}
            />
          </section>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default EditCustomer;
